import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import store from './../store';
import { T } from '../components/Translations';
import { Ajax } from '../components/Ajax';
import { PopupTypes } from '../components/Popup';
import { Project } from './Project';
import { openPopup } from '../store/actions';
import { showLoader, hideLoader } from 'Shared/Loader';

/**
 *the list of custom events occuring for specific actions
 */
export const EVENT_NAME = {
    BRAND_LIST_UPDATE: 'BRAND_LIST_UPDATE',
    BRAND_TRANSLATIONS_UPDATE: 'BRAND_TRANSLATIONS_UPDATE',
    SALES_NETWORK_LIST_UPDATE: 'SALES_NETWORK_LIST_UPDATE',
    SALES_NETWORK_PAYMENT_LIST_UPDATE: 'SALES_NETWORK_PAYMENT_LIST_UPDATE',
    UNIVERSE_LIST_UPDATE: 'UNIVERSE_LIST_UPDATE',
    ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE: 'ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE',
    ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE2: 'ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE2',
    SPARE_PART_PICKER_LIST_UPDATE: "SPARE_PART_PICKER_LIST_UPDATE",
    BILL_OF_MATERIAL_LIST_UPDATE: 'BILL_OF_MATERIAL_LIST_UPDATE',
    BILL_OF_MATERIAL_TRANSLATIONS_UPDATE: 'BILL_OF_MATERIAL_TRANSLATIONS_UPDATE',
    BILL_OF_MATERIAL_DETAIL_RELOAD: 'BILL_OF_MATERIAL_DETAIL_RELOAD',
    BILL_OF_MATERIAL_ZONE_SELECT: 'BILL_OF_MATERIAL_ZONE_SELECT',
    BILL_OF_MATERIAL_ZONE_LIST_UPDATE: 'BILL_OF_MATERIAL_ZONE_LIST_UPDATE',
    BILL_OF_MATERIAL_ZONE_LIST_UPDATE2: 'BILL_OF_MATERIAL_ZONE_LIST_UPDATE2',
    BILL_OF_MATERIAL_ZONE_ARTICLE_LIST_UPDATE: 'BILL_OF_MATERIAL_ZONE_ARTICLE_LIST_UPDATE',
    BILL_OF_MATERIAL_ZONE_ARTICLE_LIST_UPDATE2: 'BILL_OF_MATERIAL_ZONE_ARTICLE_LIST_UPDATE2',
    BILL_OF_MATERIAL_SCHEMA_UPDATE: 'BILL_OF_MATERIAL_SCHEMA_UPDATE',
    BILL_OF_MATERIAL_SCHEMA_NAME_UPDATE: 'BILL_OF_MATERIAL_SCHEMA_NAME_UPDATE',
    PRODUCT_BILL_OF_MATERIAL_LIST_UPDATE: "PRODUCT_BILL_OF_MATERIAL_LIST_UPDATE",
    SPARE_PART_LIST_UPDATE: "SPARE_PART_LIST_UPDATE",
    SPARE_PART_TRANSLATIONS_UPDATE: "SPARE_PART_TRANSLATIONS_UPDATE",
    PRODUCT_LIST_UPDATE: "PRODUCT_LIST_UPDATE",
    PRODUCT_PICKER_LIST_UPDATE: "PRODUCT_PICKER_LIST_UPDATE",
    PRODUCT_TRANSLATIONS_UPDATE: "PRODUCT_TRANSLATIONS_UPDATE",
    PARAMETER_PRODUCT_LIST_UPDATE: "PRODUCT_LIST_UPDATE",
    RELOAD_PARAMETRES_VALUE_LIST: "RELOAD_PARAMETRES_VALUE_LIST",
    RELOAD_SALES_NETWORKS_VALUE_LIST: "RELOAD_SALES_NETWORKS_VALUE_LIST",
    PRODUCT_SALES_NETWORKS_LIST_UPDATE: "PRODUCT_SALES_NETWORKS_LIST_UPDATE",
    PRODUCT_ACCESSORIES_LIST_UPDATE: "PRODUCT_ACCESSORIES_LIST_UPDATE",
    PRODUCT_ACCESSORY_PICKER_LIST_UPDATE: "PRODUCT_ACCESSORY_PICKER_LIST_UPDATE",
    FAMILY_PRODUCT_LIST_UPDATE: "FAMILY_PRODUCT_LIST_UPDATE",
    SETUP_COUNTRY_GROUP_LIST_UPDATE: "SETUP_COUNTRY_GROUP_LIST_UPDATE",
    FAMILY_BACKUP_CHECK: "FAMILY_BACKUP_CHECK",
    FAMILY_LIST_UPDATE: "FAMILY_LIST_UPDATE",
    FAMILY_TRANSLATIONS_REFRESH: "FAMILY_TRANSLATIONS_REFRESH",
    FAMILY_TRANSLATIONS_UPDATE: 'FAMILY_TRANSLATIONS_UPDATE',
    FAMILY_EVOLUTION_LIST_UPDATE: "FAMILY_EVOLUTION_LIST_UPDATE",
    FAMILY_DEFECT_LIST_UPDATE: "FAMILY_DEFECT_LIST_UPDATE",
    FAMILY_VIDEO_LIST_UPDATE: "FAMILY_VIDEO_LIST_UPDATE",
    FAMILY_CUSTOMER_WARRANTY_LIST_UPDATE: "FAMILY_CUSTOMER_WARRANTY_LIST_UPDATE",
    FAMILY_PRODUCT_WARRANTY_LIST_UPDATE: "FAMILY_PRODUCT_WARRANTY_LIST_UPDATE",
    FAMILY_SAV_INFO_UPDATE: "FAMILY_SAV_INFO_UPDATE",
    FAMILY_SAV_INFO_DIAGNOSTICS_LIST_UPDATE: "FAMILY_SAV_INFO_DIAGNOSTICS_LIST_UPDATE",
    FAMILY_INFOS_LIST_UPDATE: "FAMILY_INFOS_LIST_UPDATE",
    SETUP_PRODUCT_PARAMETER_LIST_UPDATE: "SETUP_PRODUCT_PARAMETER_LIST_UPDATE",
    SETUP_PRODUCT_PARAMETER_TRANSLATION_UPDATE: "SETUP_PRODUCT_PARAMETER_TRANSLATION_UPDATE",
    SETUP_CLIENT_TYPE_LIST_UPDATE: "SETUP_CLIENT_TYPE_LIST_UPDATE",
    SETUP_CLIENT_TYPE_TRANSLATION_UPDATE: "SETUP_CLIENT_TYPE_TRANSLATION_UPDATE",
    SETUP_COUNTRY_LIST_UPDATE: "SETUP_CLIENT_TYPE_LIST_UPDATE",
    SETUP_COUNTRY_TRANSLATION_UPDATE: "SETUP_CLIENT_TYPE_TRANSLATION_UPDATE",
    SETUP_WARRANTY_TYPE_LIST_UPDATE: "SETUP_WARRANTY_TYPE_LIST_UPDATE",
    SETUP_WARRANTY_TYPE_TRANSLATION_UPDATE: "SETUP_WARRANTY_TYPE_TRANSLATION_UPDATE",
    SETUP_NORMS_LIST_UPDATE: "SETUP_NORMS_LIST_UPDATE",
    SETUP_NORMS_TRANSLATION_UPDATE: "SETUP_NORMS_TRANSLATION_UPDATE",
    SETUP_VIDEOS_VIMEO_LIST_UPDATE: "SETUP_VIDEOS_VIMEO_LIST_UPDATE",
    SETUP_VIDEOS_VIMEO_TRANSLATION_UPDATE: "SETUP_VIDEOS_VIMEO_TRANSLATION_UPDATE",
    SETUP_DEFECT_LIST_UPDATE: "SETUP_DEFECT_LIST_UPDATE",
    SETUP_DEFECT_TRANSLATION_UPDATE: "SETUP_VIDEOS_VIMEO_TRANSLATION_UPDATE",
    SETUP_ARTICLE_TYPE_LIST_UPDATE: "SETUP_ARTICLE_TYPE_LIST_UPDATE",
    SETUP_ARTICLE_TYPE_TRANSLATION_UPDATE: "SETUP_VIDEOS_VIMEO_TRANSLATION_UPDATE",
    SETUP_RECHERCHE_NO_SERIE_LIST_UPDATE: "SETUP_RECHERCHE_NO_SERIE_LIST_UPDATE",
    SETUP_TRANSLATION_LIST_UPDATE: "SETUP_TRANSLATION_LIST_UPDATE",
    SETUP_LANGUAGE_LIST_UPDATE: "SETUP_LANGUAGE_LIST_UPDATE",
    PROCESS_LIST_UPDATE: 'PROCESS_LIST_UPDATE',
    PROCESS_LOG_LIST_UPDATE: 'PROCESS_LOG_LIST_UPDATE',
    PROCESS_LOG_DETAIL_LIST_UPDATE: 'PROCESS_LOG_DETAIL_LIST_UPDATE',
    USER_LIST_UPDATE: 'USER_LIST_UPDATE',
    SOCIETE_USER_LIST_UPDATE: 'SOCIETE_USER_LIST_UPDATE',
    SOCIETE_SALES_NETWORK_LIST_UPDATE: 'SOCIETE_SALES_NETWORK_LIST_UPDATE',
    SOCIETE_CLUB_STATUS_LIST_UPDATE: 'SOCIETE_CLUB_STATUS_LIST_UPDATE',
    ADMINISTRATORS_LIST_UPDATE: 'ADMINISTRATORS_LIST_UPDATE',
    ALLOWED_IP_LIST_UPDATE: 'ALLOWED_IP_LIST_UPDATE',
    API_ACCESS_TYPE_LIST_UPDATE: 'API_ACCESS_TYPE_LIST_UPDATE',
    BANK_CARD_LIST_UPDATE: 'BANK_CARD_LIST_UPDATE',
    CB_CONFIG_LIST_UPDATE: 'CB_CONFIG_LIST_UPDATE',
    TVA_LIST_UPDATE: 'TVA_LIST_UPDATE',
    SALES_ORDER_LIST_UPDATE: 'SALES_ORDER_LIST_UPDATE',
    WARRANTY_REQUEST_LIST_UPDATE: 'WARRANTY_REQUEST_LIST_UPDATE',
    DEVIS_LIST_UPDATE: 'DEVIS_LIST_UPDATE',
    SPARE_PART_IMAGE_LIST_UPDATE: "SPARE_PART_LIST_UPDATE",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    SIREN_LIST_UPDATE: "SIREN_LIST_UPDATE",
    SIRET_LIST_UPDATE: "SIRET_LIST_UPDATE",
    SALES_NETWORK_PICKER_LIST_UPDATE: "SALES_NETWORK_PICKER_LIST_UPDATE",
    SKILL_LIST_UPDATE: "SKILL_LIST_UPDATE",
    SKILL_TRANSLATIONS_UPDATE: 'SKILL_TRANSLATIONS_UPDATE',
    FEE_LIST_UPDATE: "FEE_LIST_UPDATE",
    DEFECT_CODE_LIST_UPDATE: "DEFECT_CODE_LIST_UPDATE",
    SOCIETE_SKILL_LIST_UPDATE: "SOCIETE_SKILL_LIST_UPDATE",
    SOCIETE_TECHNISIAN_LIST_UPDATE: "SOCIETE_TECHNISIAN_LIST_UPDATE",
    FAMILLY_SKILL_LIST_UPDATE: "FAMILLY_SKILL_LIST_UPDATE",
    FAMILY_INFO_UPDATE: "FAMILY_INFO_UPDATE",
    FAMILY_ITEM_UPDATE: "FAMILY_ITEM_UPDATE",
    SOCIETE_POSTAL_CODE_LIST_UPDATE: 'SOCIETE_POSTAL_CODE_LIST_UPDATE',
    UNIVERS_SITES_LIST_UPDATE: "UNIVERS_SITES_LIST_UPDATE",
    UNIVERS_SITES_SSO_LIST_UPDATE: "UNIVERS_SITES_SSO_LIST_UPDATE",
    SSO_LIST_UPDATE: 'SSO_LIST_UPDATE',
    MAINTENANCE_LIST_UPDATE: 'MAINTENANCE_LIST_UPDATE',
    MAINTENANCE_TRANSLATIONS_UPDATE: 'MAINTENANCE_TRANSLATIONS_UPDATE',
    UNIVERS_SITES_RESEAUX_LIST_UPDATE: 'UNIVERS_SITES_RESEAUX_LIST_UPDATE'
}

/**
 *the method for custom event creation
 * @param {string} eventName
 * @param {Function} callback
 */
export function useCustomEvent(eventName, callback) {
    useEffect(
        () => {
            if (eventName && callback) {
                let events = eventName.split(" ");
                for (let i = 0; i < events.length; i++) { // NOSONAR
                    document.addEventListener(events[i], handler);
                }
                return () => {
                    for (let i = 0; i < events.length; i++) { // NOSONAR
                        document.removeEventListener(events[i], handler);
                    }
                }

                function handler(e) {
                    callback(e);
                }
            }
        },
        [eventName, callback]
    );
}

/**
 *the method to trigger custom event
 * @param {string} eventName
 * @param {any} data
 */
export function dispatchCustomEvent(eventName, data) {
    let events = eventName && eventName.split(" ");
    if (events) {
        for (let i = 0; i < events.length; i++) { // NOSONAR
            const event = new CustomEvent(events[i], { bubbles: true, detail: data });
            document.dispatchEvent(event);
        }
    }
}

/**
 *custom hook to ensure(for front end) the login state on specific pages
 * @param {any} props
 */
export function useLoginCheck(props) {
    const historyHook = useHistory();
    useEffect(() => {
        if (!props.skipLogin && !store.getState().isLoggedIn) {
            let history = props.history || historyHook;
            history.push("/login?redirectUrl=" + window.location.pathname);
        };
    }, [props.history, props.skipLogin, historyHook]);
}

const deleteLoaderName = 'DELETE_LOADER';

/**
 *custom hook creating specific delete method for each list and form given props
 * @param {any} props { nameField, deleteUrl, backPage, deleteKeys, listUpdateEvent }
 */
export function useDelete(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { nameField, deleteUrl, backPage, backPageRoute, backPageParams, deleteKeys, listUpdateEvent, startDelete, endDelete, deleteMessage } = props;

    const onDelete = useCallback((record, callback) => { // NOSONAR
        dispatch(openPopup({
            windowKey: 'wndConfirmDelete',
            type: PopupTypes.Confirm,
            disableOutsideClick: true,
            title: <T args={[nameField ? record[nameField] : record.nom]}>message.delete_title</T>,//`Delete ‘${record.nom}‘`,
            text: <T>{deleteMessage || "message.delete_confirm"}</T>, //'This action cannot be undone. Are you sure you want to continue?',
            buttonYesText: <T>text.delete</T>,//Defines.Messages.confirmDelete,
            buttonNoText: <T>text.cancel</T>,//Defines.Messages.cancelDelete,
            yesCallback: function () {
                showLoader(deleteLoaderName);
                startDelete && startDelete();
                if (deleteKeys) {
                    let data = [];
                    deleteKeys.map((key) => data[key] = record[key])
                    Ajax.post({
                        url: deleteUrl,
                        data: {
                            data
                        },
                        success: function (response) {
                            if (response) {
                                dispatchCustomEvent(listUpdateEvent, { action: "delete" });
                                (callback && callback(response)) || (backPage && history.push(Project.getPageUrl(backPage)));
                            }

                            endDelete && endDelete();
                            hideLoader(deleteLoaderName);
                        },
                        error: function (response) {
                            if (response.message) {
                                message.error(response.message);
                            }
                            hideLoader(deleteLoaderName);
						}
                    })
                } else {
                    Ajax.get({
                        url: deleteUrl,
                        data: {
                            id: record.ID
                        },
                        success: function (response) {
                            if (response) {
                                dispatchCustomEvent(listUpdateEvent, { action: "delete" });
                                (callback && callback(response)) || (backPage && history.push(Project.getPageUrl(backPage, backPageRoute, backPageParams)));
                            }
                            endDelete && endDelete();
                            hideLoader(deleteLoaderName);
                        },
                        error: function (response) {
                            if (response.message) {
                                message.error(response.message);
                            }
                            hideLoader(deleteLoaderName);
                        }
                    })
                }

            }
        }));
    }, [deleteUrl, nameField, listUpdateEvent, endDelete, history, startDelete, deleteMessage, backPage, backPageRoute, backPageParams, deleteKeys, dispatch]);

    return onDelete;
}

export function cloneDataItem(dataItem) {
    return Object.assign({}, dataItem);
}

export function deepCopy(inObject) {
    let outObject, value, key

    if (typeof inObject !== "object" || inObject === null) {
        return inObject;
    }

    outObject = Array.isArray(inObject) ? [] : {}
    for (key in inObject) {
        value = inObject[key]
        outObject[key] = deepCopy(value)
    }

    return outObject
}