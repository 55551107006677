/**
 * the formats for different types of data
 */
export const Defines = {
    Format: {
        ServerDateTime: "YYYY-MM-DD[T]HH:mm:ss",

        LocalDate: "DD/MM/YYYY", //"MM/dd/yyyy",
        LocalDateTime: "DD/MM/YYYY HH:mm",

        //Time: "HH:mm",
        FullTime: "HH:mm:ss",
    },
    Messages: {
        Yes: "Yes",
        No: "No",
    }
};

/**
 * shorthands to get page urls
 */
export const AppPages = {
    Home: "home",

    Login: "login",
    Logout: "logout",
    Register: "register",
    Forgot: "forgot",

    Brand: "brand",
    BrandDetails: "branddetails",
    User: "user",
    UserDetails: "userdetails",
    SocieteUserDetails: "societeuserdetails",
    SalesNetwork: "salesnetwork",
    SalesNetworkDetails: "salesnetworkdetails",
    CreditCardPaymentsDetails: "creditcardpaymentsdetails",
    Universe: "universe",
    UniverseDetails: "universedetails",
    Products: "products",
    ProductDetails: "productdetails",
    BillOfMaterials: "billofmaterials",
    BillOfMaterialDetails: "billofmaterialdetails",
    BillOfMaterialExtract: "billofmaterialextract",
    SpareParts: "spareparts",
    SparePartDetails: "sparepartdetails",
    SparePartImagesUpload: "sparepartimagesupload",
    ProductParameters: "productparameters",
    Requlations: "requlations",
    Defects: "defects",
    Users: "users",
    UsersSiretChanges: "userssiretchanges",
    Jobs: "jobs",
    JobsWarrantyRequest: "jobswarrantyrequest",
    JobsWarrantyRequestDetails: "jobswarrantyrequestdetails",
    JobsSalesOrder: "jobssalesorder",
    JobsSalesOrderDetails: "jobssalesorderdetails",
    JobsDevis: "jobsdevis",

    Setup: "setup",
    SetupProductParameter: "setupproductparameter",
    SetupProductParameterDetails: "setupproductparameterdetails",
    SetupClientTypes: "setupclienttypes",
    SetupClientTypeDetails: "setupclienttypedetails",
    SetupWarrantyTypes: "setupwarrantytypes",
    SetupWarrantyTypeDetails: "setupwarrantytypedetails",
    SetupProductDefect: "setupproductdefect",
    SetupProductDefectDetails: "setupproductdefectdetails",
    SetupArticleTypes: "setuparticletypes",
    SetupArticleTypeDetails: "setuparticlestypedetails",
    SetupVideo: "setupvideo",
    SetupVideoDetails: "setupvideodetails",
    SetupNormes: "setupnormes",
    SetupNormDetails: "setupnormdetails",
    SetupCountryGrouping: "setupcountrygrouping",
    SetupCountryGroupingDetails: "setupcountrygroupingdetails",
    SetupCountries: "setupcountries",
    SetupCountryDetails: "setupcountrydetails",
    SetupRechercheNoSerie: "setuprecherchenoserie",
    SetupRechercheNoSerieDetails: "setuprecherchenoseriedetails",
    SetupTranslation: "setuptranslation",
    SetupLanguages: "setuplanguages",
    SetupLanguageDetails: "setuplanguagedetails",

    Administration: "administration",
    Administrators: "administrators",
    AdministratorsDetails: "administratorsdetails",
    AllowedIP: "allowedip",
    AllowedIPDetails: "allowedipdetails",
    BankCard: "bankcard",
    BankCardDetails: "bankcarddetails",
    ReseauConfigCBS: "reseauconfigcbs",
    ReseauConfigCBSDetails: "reseauconfigcbsdetails",
    APIAccess: "apiaccess",
    APIAccessDetails: "apiaccessdetails",
    Applications: "applications",
    ApplicationsDetails: "applicationsdetails",
    TVA: "tva",
    TVADetails: "tvadetails",
    History: "history",
    HistoryDetails: "historydetails",
    ConnectivityBlocking: "connectivityblocking",
    Skills: "skills",
    SkillsDetails: "skillsdetails",
    Fees: "fees",
    FeeDetails: "feedetails",
    DefectCode: "defectcode",
    DefectCodeDetails: "defectcodedetails",

    Process: "process",
    ProcessDetails: "processdetails",
    ProcessLog: "processlog",
    ProcessLogDetails: "processlogdetails",
    ProcessLogDetail: "processlogdetail",
    ProcessLogDetailDetails: "processlogdetaildetails",
    Charts: "charts",
    DocumentView: "documentview",
    SSO: "sso",
    SSODetails: "ssodetails",
    Maintenance: "maintenance",
    MaintenanceDetails: "maintenancedetails",

    NotFound: "notfound",
    AccessDenied: "accessdenied",
    ExternalCard: "panier_extern",

    Template: "template"
}

export const StoreKeys = {
    UserToken: "USER-TOKEN",
    UserName: "userName",
    LoggedIn: "loggedIn",
    ListStates: "listStates",
    Culture: "culture",
    Role: "role",
    Settings: "settings",
    ExpireTime: "exptime"

}

/**
 * the url endings for each action of api
 */
export const ApiUrl = {
    Login: "/account/login",
    Logout: "/account/logout",
    Register: "/account/register",
    Forgot: "/account/forgot",
    BrandList: "/api/Brands/GetListData",
    BrandUpdate: "/api/Brands/UpdateFormData",
    BrandLoad: "/api/Brands/ResetFormData",
    BrandsDelete: "/api/Brands/DeleteFormData",
    SalesNetworkList: "/api/SalesNetwork/GetListData",
    SalesNetworkUpdate: "/api/SalesNetwork/UpdateFormData",
    SalesNetworkDelete: "/api/SalesNetwork/DeleteFormData",
    SalesNetworkLoad: "/api/SalesNetwork/ResetFormData",
    SalesNetworkUniversList: "/api/UniverseSitesReseaux/GetListData",
    SalesOrderList: "/api/SalesOrder/GetListData",
    SalesOrderUpdate: "/api/SalesOrder/UpdateFormData",
    SalesOrderDelete: "/api/SalesOrder/DeleteFormData",
    SalesOrderLoad: "/api/SalesOrder/ResetFormData",
    DevisList: "/api/Devis/GetListData",
    DevisUpdate: "/api/Devis/UpdateFormData",
    DevisDelete: "/api/Devis/DeleteFormData",
    DevisLoad: "/api/Devis/ResetFormData",
    CreditCardPaymentsList: "/api/SalesNetworkPayments/GetListData",
    CreditCardPaymentsUpdate: "/api/SalesNetworkPayments/UpdateFormData",
    CreditCardPaymentsDelete: "/api/SalesNetworkPayments/DeleteFormData",
    CreditCardPaymentsLoad: "/api/SalesNetworkPayments/ResetFormData",
    BillOfMaterialList: "/api/BillOfMaterial/GetListData",
    BillOfMaterialLoad: "/api/BillOfMaterial/ResetFormData",
    BillOfMaterialUpdate: "/api/BillOfMaterial/UpdateFormData",
    BillOfMaterialDelete: "/api/BillOfMaterial/DeleteFormData",
    BillOfMaterialProductAdd: "/api/BillOfMaterial/AddBillOfMaterialProducts",
    BillOfMaterialDuplicate: "/api/BillOfMaterial/DuplicateBillOfMaterial",
    ArticleList: "/api/Article/GetListData",
    ArticleLoad: "/api/Article/ResetFormData",
    ArticleUpdate: "/api/Article/UpdateFormData",
    ArticleDelete: "/api/Article/DeleteFormData",
    ArticleSubstitute: "/api/Article/Substitute",
    ArticleProductList: "/api/ArticleProduct/GetListData",
    ArticleBillOfMaterialList: "/api/ArticleBillOfMaterial/GetListData",
    ArticleBillOfMaterialLoad: "/api/ArticleBillOfMaterial/ResetFormData",
    ArticleBillOfMaterialUpdate: "/api/ArticleBillOfMaterial/UpdateFormData",
    ArticleBillOfMaterialAdd: "/api/ArticleBillOfMaterial/AddArticleBillofMaterials",
    ArticleBillOfMaterialDelete: "/api/ArticleBillOfMaterial/DeleteFormData",
    AccessoryProductList: "/api/AccessoryProduct/GetListData",
    AccessoryProductUpdate: "/api/AccessoryProduct/UpdateFormData",
    AccessoryProductAdd: "/api/AccessoryProduct/AddProductAccessories",
    AccessoryProductDelete: "/api/AccessoryProduct/DeleteRelationData",
    ParameterProductList: "/api/ParameterProduct/GetListData",
    ParameterProductLoad: "/api/ParameterProduct/ResetFormData",
    ParameterProductUpdate: "/api/ParameterProduct/UpdateFormData",
    ParameterProductDelete: "/api/ParameterProduct/DeleteFormData",
    ParameterList: "/api/Parameter/GetListData",
    ParameterLoad: "/api/Parameter/ResetFormData",
    ParameterUpdate: "/api/Parameter/UpdateFormData",
    ParameterDelete: "/api/Parameter/DeleteFormData",
    ProductSalesNetworkList: "/api/ProductSalesNetwork/GetListData",
    ProductSalesNetworkLoad: "/api/ProductSalesNetwork/ResetFormData",
    ProductSalesNetworkUpdate: "/api/ProductSalesNetwork/UpdateFormData",
    ProductSalesNetworkDelete: "/api/ProductSalesNetwork/DeleteRelationData",
    ProductsList: "/api/Product/GetListData",
    ProductsUpdate: "/api/Product/UpdateFormData",
    RemoveBOMProduct: "/api/Product/RemoveBOMProduct",
    ProductsSavCommentaireUpdate: "/api/Product/SavCommentaireUpdate",
    ProductsDelete: "/api/Product/DeleteFormData",
    ProductLoad: "/api/Product/ResetFormData",
    ProductDuplicate: "/api/Product/DuplicateProduct",
    UniverseList: "/api/Univers/GetListData",
    UniverseLoad: "/api/Univers/ResetFormData",
    UniverseUpdate: "/api/Univers/UpdateFormData",
    UniverseDelete: "/api/Univers/DeleteFormData",
    SchemaList: "/api/Schema/GetListData",
    SchemaLoad: "/api/Schema/ResetFormData",
    SchemaUpdate: "/api/Schema/UpdateFormData",
    SchemaDelete: "/api/Schema/DeleteFormData",
    SchemaChangeLineNumbers: "/api/Schema/ChangeLineNumbers",
    ZoneAndArticleList: "/api/ZoneAndArticle/GetListData",
    ZoneList: "/api/Zone/GetListData",
    ZoneLoad: "/api/Zone/ResetFormData",
    ZoneUpdate: "/api/Zone/UpdateFormData",
    ZoneDelete: "/api/Zone/DeleteFormData",
    ZoneDuplicate: "/api/Zone/Duplicate",
    ZoneAddArticles: "/api/Zone/AddArticles",
    ZoneDeleteArticle: "/api/ArticleZone/DeleteRelationData", //"/api/Zone/DeleteArticle",
    ArticleZoneList: "/api/ArticleZone/GetListData",
    ArticleZoneLoad: "/api/ArticleZone/ResetFormData",
    TranslationList: "/api/Translations/GetListData",
    TranslationsUpdate: "/api/Translations/UpdateTranslations",
    FamiliesList: "/api/Families/GetListData",
    FamiliesLoad: "/api/Families/ResetFormData",
    FamiliesUpdate: "/api/Families/UpdateFormData",
    FamiliesParentChange: "/api/Families/ChangeParentAndLineNumbers",
    FamiliesDelete: "/api/Families/DeleteFormData",
    FamiliesBackup: "/api/Families/Backup",
    FamiliesRestore: "/api/Families/Restore",
    FamiliesNodeLoad: "/api/Families/GetFamily",
    FamilyBackupList: "/api/FamilyBackup/GetListData",
    FamilyNormeList: "/api/FamilyNorme/GetListData",
    FamilyNormeUpdate: "/api/FamilyNorme/UpdateFamilyNormes",
    FamilyProductAdd: "/api/FamilyProduct/AddProducts",
    FamilyProductList: "/api/FamilyProduct/GetListData",
    FamilyProductDelete: "/api/FamilyProduct/DeleteRelationData",
    FamilyProductPayUpdate: "/api/FamilyProduct/UpdatePaysProduit",
    FamilyEvolutionList: "/api/FamilyEvolution/GetListData",
    FamilyEvolutionLoad: "/api/FamilyEvolution/ResetFormData",
    FamilyEvolutionUpdate: "/api/FamilyEvolution/UpdateFormData",
    FamilyEvolutionDelete: "/api/FamilyEvolution/DeleteFormData",
    FamilyDefectList: "/api/FamilyDefect/GetListData",
    FamilyDefectLoad: "/api/FamilyDefect/ResetFormData",
    FamilyDefectUpdate: "/api/FamilyDefect/UpdateFormData",
    FamilyDefectDelete: "/api/FamilyDefect/DeleteFormData",
    FamilyVideoAdd: "/api/FamilyVideo/AddVideos",
    FamilyVideoList: "/api/FamilyVideo/GetListData",
    FamilyVideoDelete: "/api/FamilyVideo/DeleteRelationData",
    FamilyCustomerWarrantyList: "/api/FamilyCustomerWarranty/GetListData",
    FamilyCustomerWarrantyUpdate: "/api/FamilyCustomerWarranty/UpdateFamilyCustomerWarranty",
    FamilyProductWarrantyList: "/api/FamilyProductWarranty/GetListData",
    FamilyProductWarrantyUpdate: "/api/FamilyProductWarranty/UpdateFamilyProductWarranty",
    FamilyInfosList: "/api/FamilyInfos/GetListData",
    FamilyInfosLoad: "/api/FamilyInfos/ResetFormData",
    FamilyInfosUpdate: "/api/FamilyInfos/UpdateFormData",
    FamilyInfosDelete: "/api/FamilyInfos/DeleteFormData",
    SavInfoLoad: "/api/SavInfo/ResetFormData",
    SavInfoUpdate: "/api/SavInfo/UpdateFormData",
    SavInfoDiagnosticsList: "/api/SavInfoDiagnostics/GetListData",
    SavInfoDiagnosticsLoad: "/api/SavInfoDiagnostics/ResetFormData",
    SavInfoDiagnosticsUpdate: "/api/SavInfoDiagnostics/UpdateFormData",
    SavInfoDiagnosticsDelete: "/api/SavInfoDiagnostics/DeleteFormData",
    SavInfoDiagnosticsChangeLineNumbers: "/api/SavInfoDiagnostics/ChangeLineNumbers",
    CountryGroupList: "/api/CountryGroup/GetListData",
    CountryGroupDelete: "/api/CountryGroup/DeleteFormData",
    CountryGroupLoad: "/api/CountryGroup/ResetFormData",
    CountryGroupUpdate: "/api/CountryGroup/UpdateFormData",
    // CountryGroupDelete: "/api/CountryGroup/DeleteRelationData",
    // CountryGroupAdd: "/api/CountryGroup/AddCountries",
    // CountryGroupUpdate: "/api/CountryGroup/UpdateCountryGroup",
    VideosVimeoList: "/api/VideosVimeo/GetListData",
    VideosVimeoLoad: "/api/VideosVimeo/ResetFormData",
    VideosVimeoUpdate: "/api/VideosVimeo/UpdateFormData",
    VideosVimeoDelete: "/api/VideosVimeo/DeleteFormData",
    NormsList: "/api/Norms/GetListData",
    NormsLoad: "/api/Norms/ResetFormData",
    NormsUpdate: "/api/Norms/UpdateFormData",
    NormsDelete: "/api/Norms/DeleteFormData",
    ValueList: "/api/ValueList/GetList",
    ClientTypeList: "/api/ClientType/GetListData",
    ClientTypeLoad: "/api/ClientType/ResetFormData",
    ClientTypeUpdate: "/api/ClientType/UpdateFormData",
    ClientTypeDelete: "/api/ClientType/DeleteFormData",

    CountryList: "/api/Country/GetListData",
    CountryDelete: "/api/Country/DeleteFormData",
    CountryUpdate: "/api/Country/UpdateFormData",
    CountryLoad: "/api/Country/ResetFormData",

    LanguageList: "/api/Language/GetListData",
    LanguageDelete: "/api/Language/DeleteFormData",
    LanguageUpdate: "/api/Language/UpdateFormData",
    LanguageLoad: "/api/Language/ResetFormData",

    RechercheNoSerieList: "/api/RechercheNoSerie/GetListData",
    RechercheNoSerieDelete: "/api/RechercheNoSerie/DeleteFormData",
    RechercheNoSerieUpdate: "/api/RechercheNoSerie/UpdateFormData",
    RechercheNoSerieLoad: "/api/RechercheNoSerie/ResetFormData",

    BlocageConnectivitesList: "/api/BlocageConnectivites/GetListData",
    BlocageConnectivitesAdd: "/api/BlocageConnectivites/AddSelected",
    BlocageConnectivitesRemove: "/api/BlocageConnectivites/RemoveSelected",

    DefectList: "/api/Defect/GetListData",
    DefectLoad: "/api/Defect/ResetFormData",
    DefectUpdate: "/api/Defect/UpdateFormData",
    DefectDelete: "/api/Defect/DeleteFormData",
    WarrantyTypeList: "/api/WarrantyType/GetListData",
    WarrantyTypeLoad: "/api/WarrantyType/ResetFormData",
    WarrantyTypeUpdate: "/api/WarrantyType/UpdateFormData",
    WarrantyTypeDelete: "/api/WarrantyType/DeleteFormData",
    ArticleTypeList: "/api/ArticleType/GetListData",
    ArticleTypeLoad: "/api/ArticleType/ResetFormData",
    ArticleTypeUpdate: "/api/ArticleType/UpdateFormData",
    ArticleTypeDelete: "/api/ArticleType/DeleteFormData",
    ServiceDoAction: "/api/Services/DoAction",
    ServiceGetExportData: "/api/Services/GetExportData",
    ProcessDoAction: "/api/Process/DoAction",
    ProcessList: "/api/Process/GetListData",
    ProcessUpdate: "/api/Process/UpdateFormData",
    ProcessLoad: "/api/Process/ResetFormData",
    ProcessDelete: "/api/Process/DeleteFormData",
    ProcessLogList: "/api/ProcessLog/GetListData",
    ProcessLogUpdate: "/api/ProcessLog/UpdateFormData",
    ProcessLogLoad: "/api/ProcessLog/ResetFormData",
    ProcessLogDelete: "/api/ProcessLog/DeleteFormData",
    ProcessLogDetailList: "/api/ProcessLogDetail/GetListData",
    ProcessLogDetailUpdate: "/api/ProcessLogDetail/UpdateFormData",
    ProcessLogDetailLoad: "/api/ProcessLogDetail/ResetFormData",
    ProcessLogDetailDelete: "/api/ProcessLogDetail/DeleteFormData",
    CommonTestCrypt: "/api/Common/TestCrypt",
    CommonTestDeCrypt: "/api/Common/TestDeCrypt",
    UserList: "/api/Societe/GetListData",
    UserUpdate: "/api/Societe/UpdateSociete",
    UserLoad: "/api/Societe/ResetFormData",
    UserDelete: "/api/Societe/DeleteFormData",
    ConnectAsUser: "/api/User/ConnectAsUser",
    SocieteUserList: "/api/User/GetListData",
    SocieteUserUpdate: "/api/User/UpdateUser",
    SocieteUserLoad: "/api/User/ResetFormData",
    SocieteUsersDelete: "/api/User/DeleteFormData",
    SocieteUserCheckEmail: "/api/User/CheckEmail",
    SocieteUserCheckIsUserInWso: "/api/User/CheckIsUserInWso",
    SocieteVerifySiret: "/api/Societe/VerifySiret", //{"siret": "0000000"}
    SocieteChangeSiret: "/api/Societe/ChangeSiret", //{ "old_siret": "0000000", "new_siret": "0000000", delete: false}
    SocieteSalesNetworkList: "/api/SalesNetworkSociete/GetListData",
    SocieteSalesNetworkUpdate: "/api/SalesNetworkSociete/UpdateFormData",
    SocieteSalesNetworkLoad: "/api/SalesNetworkSociete/ResetFormData",
    SocieteSalesNetworksDelete: "/api/SalesNetworkSociete/DeleteFormData",
    SocieteClubStatusList: "/api/SocieteClubStatus/GetListData",
    SocieteClubStatusUpdate: "/api/SocieteClubStatus/UpdateFormData",
    SocieteClubStatusLoad: "/api/SocieteClubStatus/ResetFormData",
    SocieteClubStatusDelete: "/api/SocieteClubStatus/DeleteFormData",
    SystemUserList: "/api/SystemUser/GetListData",
    SystemUserUpdate: "/api/SystemUser/UpdateFormData",
    SystemUserLoad: "/api/SystemUser/ResetFormData",
    SystemUserDelete: "/api/SystemUser/DeleteFormData",
    AuthorizationAccessList: "/api/AuthorizationAccess/GetListData",
    AuthorizationAccessUpdate: "/api/AuthorizationAccess/UpdateFormData",
    AuthorizationAccessLoad: "/api/AuthorizationAccess/ResetFormData",
    AuthorizationAccessDelete: "/api/AuthorizationAccess/DeleteFormData",
    ApiKeyList: "/api/ApiKey/GetListData",
    ApiKeyUpdate: "/api/ApiKey/UpdateFormData",
    ApiKeyLoad: "/api/ApiKey/ResetFormData",
    ApiKeyDelete: "/api/ApiKey/DeleteFormData",
    CGVList: "/api/CGV/GetListData",
    CGVUpdate: "/api/CGV/UpdateFormData",
    CGVLoad: "/api/CGV/ResetFormData",
    CGVDelete: "/api/CGV/DeleteFormData",
    ReseauConfigCBSList: "/api/ReseauConfigCBS/GetListData",
    ReseauConfigCBSUpdate: "/api/ReseauConfigCBS/UpdateFormData",
    ReseauConfigCBSLoad: "/api/ReseauConfigCBS/ResetFormData",
    ReseauConfigCBSDelete: "/api/ReseauConfigCBS/DeleteFormData",
    SalesNetworkTVAList: "/api/SalesNetworkTVA/GetListData",
    SalesNetworkTVAUpdate: "/api/SalesNetworkTVA/UpdateFormData",
    SalesNetworkTVALoad: "/api/SalesNetworkTVA/ResetFormData",
    SalesNetworkTVADelete: "/api/SalesNetworkTVA/DeleteFormData",
    HistoryList: "/api/History/GetListData",
    HistoryUpdate: "/api/History/UpdateFormData",
    HistoryLoad: "/api/History/ResetFormData",
    HistoryDelete: "/api/History/DeleteFormData",
    ExternalCardList: "/api/WebService/ExternalCard/GetPaymentData",
    SubmitPayment: "/api/WebService/ExternalCard/SubmitPayment",
    ArticleSalesNetworkSalesOrderList: "/api/ArticleSalesNetworkSalesOrder/GetListData",
    //ArticleSalesNetworkImageList: "/api/ArticleSalesNetworkImage/GetListData",
    //ArticleSalesNetworkImageDelete: "/api/ArticleSalesNetworkImage/DeleteFormData",
    //ArticleSalesNetworkImageGetArticleSalesNetworks: "/api/ArticleSalesNetworkImage/GetArticleSalesNetworks",
    //ArticleSalesNetworkImageUpdate: "/api/ArticleSalesNetworkImage/SaveImages",
    // ArticleImageList: "/api/Article/GetImageList",
    //  ArticleImageDelete: "/api/Article/DeleteImage",
    ArticleImages: "/api/Article/GetArticleImages",
    ArticleImageUpdate: "/api/Article/SaveImages",
    WarrantyRequestList: "/api/WarrantyRequest/GetListData",
    WarrantyRequestLoad: "/api/WarrantyRequest/ResetFormData",
    WarrantyRequestUpdate: "/api/WarrantyRequest/UpdateFormData",
    WarrantyRequestDelete: "/api/WarrantyRequest/DeleteFormData",
    WarrantyRequestAccepted: "/api/WarrantyRequest/Accepted",
    WarrantyRequestDenied: "/api/WarrantyRequest/Denied",
    WarrantyRequestPersonSatcUpdate: "/api/WarrantyRequest/PersonneSatc",

    SalesNetworkNatureCodeList: "/api/SalesNetworkNatureCode/GetListData",
    SalesNetworkNatureCodeUpdate: "/api/SalesNetworkNatureCode/UpdateFormData",
    SalesNetworkNatureCodeLoad: "/api/SalesNetworkNatureCode/ResetFormData",
    SalesNetworkNatureCodeDelete: "/api/SalesNetworkNatureCode/DeleteFormData",

    ClubSendEmail: "/api/WebService/Club/sendEmail",

    SkillList: "/api/Skill/GetListData",
    SkillLoad: "/api/Skill/ResetFormData",
    SkillUpdate: "/api/Skill/UpdateFormData",
    SkillDelete: "/api/Skill/DeleteFormData",

    FeeList: "/api/Fee/GetListData",
    FeeLoad: "/api/Fee/ResetFormData",
    FeeUpdate: "/api/Fee/UpdateFormData",
    FeeDelete: "/api/Fee/DeleteFormData",

    FeeExport: "/api/Fee/ExportData",
    FeeImport: "/api/Fee/ImportData",

    DefectCodeList: "/api/DefectCode/GetListData",
    DefectCodeLoad: "/api/DefectCode/ResetFormData",
    DefectCodeUpdate: "/api/DefectCode/UpdateFormData",
    DefectCodeDelete: "/api/DefectCode/DeleteFormData",

    SocieteSkillList: "/api/SocieteSkill/GetListData",
    SocieteSkillLoad: "/api/SocieteSkill/ResetFormData",
    SocieteSkillUpdate: "/api/SocieteSkill/UpdateFormData",
    SocieteSkillDelete: "/api/SocieteSkill/DeleteFormData",
    SocieteSkillAdd: "/api/SocieteSkill/AddSkills",
    SocieteSkillBatchUpload: "/api/SocieteSkill/BatchUpload",

    FamillySkillList: "/api/FamillySkill/GetListData",
    FamillySkillLoad: "/api/FamillySkill/ResetFormData",
    FamillySkillUpdate: "/api/FamillySkill/UpdateFormData",
    FamillySkillDelete: "/api/FamillySkill/DeleteRelationData",
    FamillySkillAdd: "/api/FamillySkill/AddSkills",

    SocietePostalCodeList: "/api/SocietePostalCode/GetListData",
    SocietePostalCodeUpdate: "/api/SocietePostalCode/UpdateFormData",
    SocietePostalCodeLoad: "/api/SocietePostalCode/ResetFormData",
    SocietePostalCodeDelete: "/api/SocietePostalCode/DeleteFormData",

    SocieteTechnisianList: "/api/SocieteTechnisian/GetListData",
    SocieteTechnisianUpdate: "/api/SocieteTechnisian/UpdateFormData",
    SocieteTechnisianLoad: "/api/SocieteTechnisian/ResetFormData",
    SocieteTechnisianDelete: "/api/SocieteTechnisian/DeleteFormData",

    UniversSitesList: "/api/UniversSites/GetListData",
    UniversSitesUpdate: "/api/UniversSites/UpdateFormData",
    UniversSitesLoad: "/api/UniversSites/ResetFormData",
    UniversSitesDelete: "/api/UniversSites/DeleteFormData",
    UniversSitesSSOList: "/api/UniversSitesSSO/GetListData",
    UniversSitesSSOUpdate: "/api/UniversSitesSSO/UpdateFormData",
    UniversSitesSSOLoad: "/api/UniversSitesSSO/ResetFormData",
    UniversSitesSSODelete: "/api/UniversSitesSSO/DeleteFormData",
    UniverseSitesReseauxList: "/api/UniverseSitesReseaux/GetListData",
    UniverseSitesReseauxUpdate: "/api/UniverseSitesReseaux/UpdateReseaux",
    SSOList: "/api/SSO/GetListData",
    SSOUpdate: "/api/SSO/UpdateFormData",
    SSOLoad: "/api/SSO/ResetFormData",
    SSODelete: "/api/SSO/DeleteFormData",
    MaintenanceList: "/api/Maintenance/GetListData",
    MaintenanceUpdate: "/api/Maintenance/UpdateFormData",
    MaintenanceLoad: "/api/Maintenance/ResetFormData",
    MaintenanceDelete: "/api/Maintenance/DeleteFormData",

    SetupTranslationList: "/api/SetupUITranslations/GetListData",
    SetupTranslationUpdate: "/api/SetupUITranslations/UpdateFormData",
    SetupTranslationLoad: "/api/SetupUITranslations/ResetFormData",
    SetupTranslationDelete: "/api/SetupUITranslations/DeleteFormData",

    SetupUITranslationsImport: "/api/SetupUITranslations/ImportData",
    SetupUITranslationsExport: "/api/SetupUITranslations/ExportData",
}

/**
 * basic user roles for proejctthe url endings for each action of api
 */
export const UserRoles = {
    SUPERADMIN: "superadmin",
    ADMIN: "admin",
    SATC: "satc",
    DISPATCHER: "dispatcher"
}

export const SatcType = {
    SATC_PAS_DEMANDE: 'non-demandee',
    SATC_ATTENTE: 'en-cours',
    SATC_VALIDE: 'acceptee',
    SATC_REFUSE: 'refusee'
}

export const StatusCommande = {
    ATTENTE_SATC: 'attente-satc',
    CREEE: 'creee',
    ATTENTE: 'attente',
    EXPEDIEE: 'expediee',
    SOLDEE: 'soldee',
    ANNULEE: 'annulee',
    ATTENTE_PAIEMENT: 'attente-paiement',
    VALIDEE: 'validee',
    REFUSEE_PRESTATAIRE_PAIMENT: 'refusee-prestataire-paiement',
    ATTENTE_BANQUE: 'attente-banque'
}

export const ValideCommande = {
    VALIDE_EN_COURS: 'en-cours',
    VALIDE_VALIDE: 'validee',
    VALIDE_EXPORTE: 'expediee',
    VALIDE_TRANSMISE: 'transmise',
    VALIDE_ACCEPTEE: 'acceptee',
    VALIDE_REFUSEE: 'refusee',
    VALIDE_PARTIEL: 'partielle',
    VALIDE_NON_RECEVABLE: 'non-recevable'
}

/**
 * basic Schedule Types for the proejct services
 */
export const ScheduleType = {
    SECOND: 'second',
    MINUTE: 'min',
    HOUR: 'hour',
    DAILY: 'day',
    WEEKLY: 'week',
    MONTHLY: 'month',
    YEARLY: 'year'
}

export const UniversConfig = {
    sauter: {
        name: "sauter",
        color: "#75af4a"
    },
    therpac: {
        name: "therpac",
        color: "grey",
    },
    atlantic: {
        name: "atlantic",
        color: "red",
    },
    default: {
        name: "sauter",
        color: "#75af4a"
    },
};

